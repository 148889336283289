@import (reference) '../../app.less';

.form-container {
    border: 1px solid white;
    background-color: white;
    border-radius: 20px;
    min-width: 350px;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.contenido {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.icono {
    font-size: 50px;
    color: @primary-color
}
@primary-color: #1DA57A;