
.logo {
    height: 32px;
    margin: 16px;
    color: #fff;

    & h3 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
}

.icono_logo {
    font-size: 30px;
    color: @primary-color
}

.contabilidad .ant-form-item-explain,
.contabilidad .ant-form-item-extra {
    min-height: 0 !important;
    font-size: 10px !important;
    line-height: 0px !important;
    padding-top: 2px;
}

.contabilidad .ant-form-item {
    margin-bottom: 5px !important
}

@media print {
    body {
        background: #fff  !important;
    }

    .noimprimir {
        display: none;
    }

    @page {
        size: A4 portrait;
    }
}
@primary-color: #1DA57A;