@import (reference) '../../app.less';

#tarjeta {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.texto {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.texto-grande {
    font-size: 18px;
}

.text-encabezado {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.text-cliente h5,
.text-cliente h6 {
    line-height: 16px;
    font-weight: 500;
}

.text-factura {
    color: #075945;

    & h1 {
        line-height: 40px;
    }

    & h5 {
        line-height: 15px;
    }

    & h6 {
        line-height: 15px;
        color: #000
    }
}

.cuadrado {
    border: 2px solid #000;
}

.bottom {
    border-bottom: 2px solid #000;
}

.left {
    border-left: 2px solid #000;
}

.total {
    color: #fff;
    font-size: 18px;
    background-color: #075945;
}

.text-tabla {
    padding: 5px 10px;
}


.ant-divider-horizontal {
    min-width: 0 !important;
    margin-top: 15px !important;
    width: 200mm !important;
    margin-left: 5mm !important;
}

.ant-divider {
    border-top: 2px solid #ddd !important;
}

.imprimir {
    width: 210mm;
    height: 297mm;
    min-height: 297mm;
}

.paginador {
    position: fixed;
    bottom: 0;
    left: 10%; 
    font-size: 10px;
    z-index: 1000;
    width: 75%;
    text-align: center;
    background-color: #fff;
    padding: 8px 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
    .ant-form-item {
        margin-bottom: 0;
    }
}

@media print {

    .ocultar {
        display: none !important;
    }

    .ajustar {
        margin: 0 !important;
        padding: 0 !important;
        float: none;
        background: #fff  !important;
    }

    .imprimir {
        width: 100%;
        height: 100%;
        min-height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }

    .ant-divider-horizontal {
        width: 95% !important;

    }

    .break {
        height: 0;
        break-after: always;
    }
}
@primary-color: #1DA57A;